// utils/db.js

import axios from 'axios';
import { api } from '../contexts/AuthContext';

// // Use an environment variable to determine the API URL
// const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://softmax.mn/api';

// const api = axios.create({
//   baseURL: API_BASE_URL,
//   headers: {
//     'Content-Type': 'application/json',
//   },
//   withCredentials: true,
// });

// api.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers['Authorization'] = `Token ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export { api };

// export const saveCarListing = async (formData) => {
//   try {
//     const response = await api.post('/listings/', formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error saving car listing:', error.response ? error.response.data : error.message);
//     throw error;
//   }
// };

export const saveCarListing = async (formData) => {
  try {
    const response = await api.post('/listings/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log('API Response:', response);
    return response.data;
  } catch (error) {
    console.error('Error saving car listing:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const updateCarSaveCount = async (carId, isSaved) => {
  try {
    const response = await api.post(`/listings/${carId}/toggle_save/`, { save: isSaved });
    return response.data;
  } catch (error) {
    console.error('Error updating save count:', error);
    throw error;
  }
};



export const getCarListings = async (page = 1) => {
  try {
    const response = await api.get(`/listings/?page=${page}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching car listings:', error);
    throw error;
  }
};

export const deleteCarListing = async (carId) => {
  try {
    const response = await api.delete(`/listings/${carId}/`);
    return response.data;
  } catch (error) {
    console.error('Error deleting car listing:', error);
    throw error;
  }
};

export const incrementCarViewCount = async (carId) => {
  try {
    const response = await api.post(`/listings/${carId}/increment_view/`, {});
    return response.data;
  } catch (error) {
    console.error('Error incrementing view count:', error);
    throw error;
  }
};

export const markMessagesAsRead = async (chatId) => {
  try {
    await api.post('/messages/mark_as_read/', { chat_id: chatId });
  } catch (error) {
    console.error('Error marking messages as read:', error);
    throw error;
  }
};

export const getChats = async () => {
  try {
    const response = await api.get('/chats/');
    return response.data.sort((a, b) => new Date(b.last_message_time) - new Date(a.last_message_time));
  } catch (error) {
    console.error('Error fetching chats:', error);
    throw error;
  }
};


export const sendMessage = async (chatId, message) => {
  try {
    console.log('Sending message to chat:', chatId, 'Message:', message);
    const response = await api.post('/messages/', { chat: chatId, content: message });
    console.log('Message sent:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error sending message:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const createOrGetChat = async (otherUserId) => {
  try {
    console.log('Creating or getting chat with user:', otherUserId);
    const response = await api.post('/chats/create_or_get_chat/', { other_user_id: otherUserId });
    console.log('Chat response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating or getting chat:', error.response ? error.response.data : error.message);
    throw error;
  }
};
export const getChatMessages = async (chatId) => {
  try {
    const response = await api.get(`/messages/?chat_id=${chatId}`);
    console.log('API response for messages:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw error;
  }
};


export const getUserInfo = async () => {
  try {
    const response = await api.get('/user-info/');
    return response.data;
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};

export const purchaseSlots = async (slots) => {
  try {
    const response = await api.post('/listings/purchase_slots/', { slots });
    return response.data;
  } catch (error) {
    console.error('Error purchasing slots:', error);
    throw error;
  }
};

export const getUser = async () => {
  try {
    const response = await api.get('/user/');
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await api.get(`/verify-email/?token=${token}`);
    return response.data;
  } catch (error) {
    console.error('Email verification error:', error.response ? error.response.data : error.message);
    throw error;
  }
};