// src/components/SignUp.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { TextField, Button, Typography, Container, Box, Dialog, DialogTitle, DialogContent, DialogActions, Alert, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { validatePassword, isPasswordValid } from '../utils/passwordValidation';

export default function SignUp() {
  const theme = useTheme();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { signup } = useAuth();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Password validation state
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    letter: false,
    number: false,
    specialChar: false,
  });

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = (pwd) => {
    const criteria = {
      length: pwd.length >= 8,
      letter: /[A-Za-z]/.test(pwd),
      number: /\d/.test(pwd),
      specialChar: /[_!@#$%^&*(),.?":{}|<>]/.test(pwd),
    };
    setPasswordCriteria(criteria);
    return Object.values(criteria).every(Boolean);
  };

  const handlePasswordChange = (e) => {
    const pwd = e.target.value;
    setPassword(pwd);
    const criteria = validatePassword(pwd);
    setPasswordCriteria(criteria);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setError('');

    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }

    if (!validatePassword(password)) {
      setError('Please ensure your password meets all the requirements.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await signup(username, email, phoneNumber, password);
      setIsDialogOpen(true);
    } catch (error) {
      setError(`Failed to create an account: ${error.message || JSON.stringify(error)}`);
      console.error("Signup error:", error);
    }
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    navigate('/login');  // Redirect to login page after closing the dialog
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Бүртгүүлэх
        </Typography>
        {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal" required fullWidth id="username" label="Хэрэглэгчийн Нэр"
            name="username" autoComplete="username" autoFocus
            value={username} onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="normal" required fullWidth id="email" label="Имэйл Хаяг"
            name="email" autoComplete="email"
            value={email} onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal" required fullWidth id="phoneNumber" label="Утасны Дугаар"
            name="phoneNumber" autoComplete="tel"
            value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            margin="normal" required fullWidth name="password" label="Нууц Үг"
            type="password" id="password" autoComplete="new-password"
            value={password} onChange={handlePasswordChange}
          />
          <Box sx={{ width: '100%', mt: 1 }}>
            <Typography variant="subtitle2">Нууц үг эдгээрийг агуулах ёстой:</Typography>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  {passwordCriteria.length ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary="Дор хаяж 8 оронтой" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {passwordCriteria.letter ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary="Доод тал нь 1 үсэг" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {passwordCriteria.number ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary="Доод тал нь 1 тоо" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {passwordCriteria.specialChar ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                </ListItemIcon>
                <ListItemText primary='Доод тал нь 1 тэмдэгт (_!@#$%^&*(),.?":{}|<>)' />
              </ListItem>
            </List>
          </Box>
          <TextField
            margin="normal" required fullWidth name="confirmPassword" label="Нууц Үг Баталгаажуулах"
            type="password" id="confirmPassword" autoComplete="new-password"
            value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}
            disabled={
              !username ||
              !email ||
              !phoneNumber ||
              !password ||
              !confirmPassword ||
              !Object.values(passwordCriteria).every(Boolean) ||
              password !== confirmPassword
            }
          >
            Бүртгүүлэх
          </Button>
        </Box>
      </Box>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Имэйлээ баталгаажуулна уу</DialogTitle>
        <DialogContent>
          <Typography>
            Бид таны имэйл хаягруу баталгаажуулах холбоос явууллаа. Та холбоосон дээр дарч цааш үргэлжлүүлнэ үү.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog> 
    </Container>
  );
}