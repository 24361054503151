import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { AiOutlineClose } from 'react-icons/ai';

const Footer = () => {
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const modalRef = useRef(null);
  const closeButtonRef = useRef(null);

  const openTerms = () => {
    setIsTermsOpen(true);
  };

  const closeTerms = () => {
    setIsTermsOpen(false);
  };

  // Close modal on Escape key press and manage body overflow
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        closeTerms();
      }
    };

    if (isTermsOpen) {
      document.addEventListener('keydown', handleEscape);
      // Prevent background scrolling
      document.body.style.overflow = 'hidden';
      // Focus on close button when modal opens
      closeButtonRef.current.focus();
    } else {
      document.removeEventListener('keydown', handleEscape);
      // Re-enable background scrolling
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'auto';
    };
  }, [isTermsOpen]);

  return (
    <section className="footer">
      <div className="footer-content">
        <div className="contact-info">
          <p>
            Contact us: <a href="mailto:support@softmax.mn">support@softmax.mn</a>
          </p>
        </div>
        <div className="terms">
          <button className="terms-button" onClick={openTerms}>
          Үйлчилгээний нөхцөл
          </button>
        </div>
      </div>

      {isTermsOpen && (
        <div
          className="modal-overlay"
          onClick={closeTerms}
          role="dialog"
          aria-modal="true"
          aria-labelledby="terms-title"
        >
          <div
            className="modal"
            onClick={(e) => e.stopPropagation()}
            ref={modalRef}
          >
            <div className="modal-header">
              <h2 id="terms-title">Үйлчилгээний нөхцөл</h2>
              <button
                className="close-button"
                onClick={closeTerms}
                aria-label="Close Terms and Conditions"
                ref={closeButtonRef}
              >
                <AiOutlineClose size={24} />
              </button>
            </div>
            <div className="modal-content">
              <pre className="terms-text">
                {`I. Нийтлэг үндэслэл

1.1. Ложитс Софтмакс ХХК (цаашид “Компани” гэх) нь автомашины зарын вэбсайт болон iOS, Android үйлдлийн системтэй гар утасны аппликейшн дээр үзүүлэх үйлчилгээний нөхцөлийг нийтэлж байна.

1.2. Энэхүү нөхцөл нь хэрэглэгч (цаашид “Хэрэглэгч” гэх) сайтыг ашиглахаасаа өмнө хүлээн зөвшөөрч баталгаажуулсны үндсэн дээр хэрэгжинэ.

1.3. Энэхүү нөхцөлд өөрөөр заагаагүй бол дараах нэр томьёог дор дурдсан утгаар ойлгоно:

    Компани – Ложитс Софтмакс ХХК;
    Сайт – автомашины зарын вэбсайт болон iOS, Android үйлдлийн системтэй гар утасны аппликейшн болох автомашины зар байршуулдаг платформ (цаашид “Сайт” гэх);
    Хэрэглэгч – энэхүү нөхцөлийг хүлээн зөвшөөрөн Компанийн үйлчилгээг авч буй хувь хүн болон хуулийн этгээд;
    Үйлчилгээ – сайтын тусламжтайгаар Компанийн үзүүлж буй төлбөргүй болон төлбөртэй үйлчилгээ.

1.4. Хэрэв Хэрэглэгч нь энэхүү нөхцөлийг хүлээн зөвшөөрөөгүй бол сайтыг орхиж, аппликейшнийг устгахыг зөвлөж байна. Сайтын үйлчилгээг Хэрэглэгч авахыг энэхүү үйлчилгээний нөхцөлөөр зохицуулна. Сайтаар үйлчлүүлснээр Хэрэглэгч энэхүү нөхцөлтэй танилцаж, хүлээн зөвшөөрсөнд тооцно.

1.5. Сайтын аль нэг үйлчилгээг ашиглаж эхлэх, аппликейшн суулгах эсвэл бүртгүүлснээр Хэрэглэгч нь үйлчилгээний нөхцөлийг бүрэн эхээр нь хүлээн зөвшөөрсөнд тооцно. Хэрэв Хэрэглэгч энэхүү нөхцөлийн аль нэг заалтыг хүлээн зөвшөөрөхгүй тохиолдолд Сайтын үйлчилгээг авах эрхгүй болно.

1.6. Компани нь интернет хэрэглэгчдэд тус нөхцөлийн дагуу үйлчилгээг ашиглахыг санал болгож байна.

1.7. Хэрэглэгчдийн дунд явагдаж буй бүх хэлэлцээр Компанийн оролцоогүйгээр явагдана. Компани нь зөвхөн автомашины зар байршуулах платформоор хангадаг болно.

II. Зар нийтлэх журам

2.1. Хэрэглэгч нь Сайтын заасан хуудсыг бөглөснөөр өөрийн санал болгож буй автомашин, автомашины хэрэгсэл, үйлчилгээг сайтад зарлан нийтлэх эрхтэй.

2.2. Хэрэглэгч Сайтын гишүүнээр саадгүй бүртгүүлэх эрхтэй бөгөөд бүртгэлд өөрт ирсэн нууц кодоороо Сайтанд нэвтэрч, Сайтын үйлчилгээг авах эрхтэй.

2.3. Сайтын үйлчилгээг авснаар энэхүү үйлчилгээний нөхцөлд заасан дүрэм журмыг заавал мөрдөхийг хүлээн зөвшөөрсөнд тооцно.

2.4. Хэрэглэгч Сайт уруу ордог өөрийн нууц кодоо нууцалж, нууц кодтой холбоотой бүх асуудлын хариуцлагыг өөрөө үүрнэ. Хэрэглэгч зөвхөн өөрийн утасны дугаар, нууц код ашиглан Сайтын үйлчилгээг авах эрхтэй.

2.5. Нэг Хэрэглэгч (хувь хүн болон хуулийн этгээд) нэг л бүртгэлтэй байна. Нэгээс илүү бүртгэлтэй байх нь хориотой тул автомат системээр тогтоогдсон давхар бүртгэлүүд түр хаагдах болон түгжигдэх болно.

2.6. Хэрэглэгч Сайт уруу ордог өөрийн нууц кодоо нууцалж, гуравдагч этгээдэд өгөхгүй байх үүрэгтэй.

2.7. Автомашин болон холбогдох бүтээгдэхүүн, үйлчилгээг санал болгон зар нийтэлж буй Хэрэглэгч нь өөрийн зарын мэдээллийг Сайтын журмын дагуу нийтлэх эрхтэй. Зар нийтэлснээр зарын эзэн нь холбогдох хууль тогтоомжийн дагуу нийтэлсэн автомашин, барааг зарах, үйлчилгээг үзүүлэх эрхтэй гэж ойлгоно.

2.8. Зарын эзэн нь борлуулж буй автомашин, бүтээгдэхүүн, үйлчилгээнийхээ чанар, аюулгүй байдал, хууль дүрэмд харшлахгүй байх тал дээр бүрэн хариуцна.

2.9. Худалдан авагчийн талаас автомашины баталгаат хугацаа, чанар, аюулгүйн байдлыг, түүнчлэн санал болгосон үйлчилгээний тусгай зөвшөөрлийг нэхэмжилсэн тохиолдолд зарын эзэн буюу худалдагчийн талаас батлан дааж, тэдний хүсэлтийг биелүүлэх үүрэгтэй.

2.10. Зарын эзэн нь өөрийн зарлаж буй автомашин, бүтээгдэхүүнийхээ дэлгэрэнгүй мэдээллийг мэддэг байх шаардлагатай бөгөөд буруу мэдээлэл оруулсан тохиолдолд нэмэлт мэдээллийг зард засвар оруулан нэмэх үүрэгтэй.

2.11. Хүргэлтийн үйлчилгээний нөхцөл нь барааны тайлбар хэсэгт бичигдсэн байх ба үйлчилгээ үзүүлэх нөхцөл нь үйлчилгээний тайлбарт бичигдэх ёстойг анхаарах. Худалдаалж буй автомашин, бүтээгдэхүүн болон санал болгож буй үйлчилгээнийхээ үнэ хөлсийг зарын эзэн үнэлэх ба хууль дүрэмд харшлахгүй байна.

2.12. Зарын эзэн нь өөрийн автомашин, бүтээгдэхүүнээ идэвхтэй сурталчлах эрхтэй бөгөөд өрсөлдөгч худалдагчдад саад болохгүй байх үүрэгтэй. Үүнд эдгээр зүйлсийг хориглохгүй болно:

    Өөр бусад зарын самбар, худалдаалах талбай, интернет дуудлага худалдаа болон интернет дэлгүүрийн талаарх мэдээлэл.

2.13. Компани нь нийтлэгдсэн зарын хугацааг сунгах, зарын байршлыг өөрчлөх эрхтэй. Хэрэв зарын эзэн нь Сайтын дотоод журам болон хууль дүрмийг зөрчиж, алдаатай зар нийтэлсэн тохиолдолд Компани зар нийтлэхийг хориглож, устгах эрхтэй.

2.14. Зар оруулахад дараах зүйлсийг хориглоно:

    Нэг хаягаас ижил зар нийтлэх;
    Зарын утга санаа давхцах;
    Өөр хоёр хаягаас нэг зарыг хуулж зар оруулах;
    Бараа бүтээгдэхүүн, үйлчилгээ, ажлын байрны зар оруулахдаа "хайж байна", "худалдаж авна", "санал хүлээж байна" гэж зарлах;
    Зарын гарчиг, утга агуулга нь зарын категорид тохирохгүй байх;
    Зарын гарчигт цэг тэмдэг болон өөр бусад тэмдэгтүүд бичих;
    Зарын гарчиг, оруулсан зураг, зарын тайлбар нь хоорондоо утга санааны зөрчилтэй байх;
    Нэг зар дээр хэд хэдэн автомашин зэрэг зарлах;
    Зар нийтлэхдээ өөр бусад сайтын холбоос (линк), нийтийн сүлжээний хаяг болон Сайтын холбоос бичиж оруулах;
    Улсын хэмжээний хориотой бараа бүтээгдэхүүнийг зарлан нийтлэх;
    Нийтэлж буй зарын утга, байршил нь тухайн зарлагчийн Сайтанд бүртгүүлсэн хотын байршлаас зөрөх.

Зар нь Сайтын админаар дамжин хянагдаж, шаардлага хангасан бол нийтлэгдэнэ.

2.15. Дараах зүйлсийг сурталчилан нийтлэхийг хориглоно:

    Хулгайн болон хууль бус автомашин, бараа, үйлчилгээ;
    Хүний эрүүл мэнд, аюулгүй байдалд хор хөнөөлтэй, аюултай бараа;
    Улсын шагнал, тэмдэг, хувь хүний бичиг баримт болон эдгээрийн албан бланк;
    Зэвсэг, тэсэрч дэлбэрэх бодис;
    Хуурамч болон залилангийн шинжтэй зар оруулбал хуулийн дагуу хариуцлага хүлээх болно.

2.16. Зар нийтлэх дүрэм

2.16.1. Зарлагчийн хувийн мэдээлэл

    Нэр бичих талбарт ямар нэгэн сайт, холбоо барих утасны дугаар, хаяг бичихгүй байх.
    Зарлагч нь нэрээ үнэн зөв, албан ёсны бүтэн нэрээ бичнэ.

2.16.2. Зарын гарчиг

    Зарын гарчигт зөвхөн зарлаж буй автомашин, үйлчилгээ, барааны нэрийг бичнэ. Бусад мэдээллийг зарын тайлбар хэсэгт бичнэ. Гарчиг нь зарын тайлбартайгаа нийцэх ёстой.
    Үндэслэлгүй том үсэг, таслал цэг тэмдэг ашиглахыг хориглоно.
    Зарын гарчиг хэсэгт ямар нэгэн сайт, холбоо барих утасны дугаар, хаяг оруулахыг хориглоно.
    Олон нийтийн анхаарал татсан үгийг ашиглахгүй байх. Жишээ нь: "Яаралтай", "Анхаараарай", "Урамшуулалтай", "Хямдралтай" гэх мэт.

2.16.3. Зарын тайлбар

    Зарын тайлбарт үндэслэлгүй цэг тэмдэг, том үсэг ашиглахгүй байх.
    Зарлаж буй автомашин, үйлчилгээний тухай дэлгэрэнгүй тайлбарлан бичнэ.
    Зарын тайлбар нь зөвхөн автомашин, үйлчилгээний тухай тодорхой мэдээллийг оруулах ба бусад илүү дэлгэрэнгүй мэдээлэл, сурталчилгаа хийхгүй.
    Зарын тайлбарт зарлаж буй бараанаас гадна бусад үнийн санал, барааны саналыг дурдахыг хориглоно.
    Зарын тайлбарт ямар нэгэн сайт, холбоо барих утасны дугаар, хаяг оруулахыг хориглоно.

2.16.4. Зарын фото зураг

    Зураг нь зарын утга агуулгатай нийцсэн байх ёстой.
    Фото зураг нь ямар нэгэн тамгатай тохиолдолд ашиглахыг хориглоно.
    Фото зурагт албан байгууллагын нэрийг тамга болгон оруулахыг зөвшөөрнө. Тамга нь зургийн доод эсвэл дунд хэсэгт байрлана. Байгууллагын нэр тунгалаг байх ба жижиг үсэг ашигласан байна.
    Зурагт бусдын анхаарал татсан текст оруулж засахгүй байх. Жишээ нь: "Яаралтай", "Анхаараарай", "Хямдарсан" гэх мэт.
    Зурагт холбоо барих хаяг оруулахгүй байхыг хатуу анхаарна уу.

2.16.5. Үнэ

    Зөвхөн санал болгож буй автомашин, үйлчилгээний үнийг бичнэ.
    Хэрэв үнийн дүн өмнөхөөсөө хямдарсан бол хямдруулсан шалтгаан буюу автомашины байдал, засвар үйлчилгээ гэх мэт тайлбарыг зарын тайлбар хэсэгт бичнэ.
    Зарын гарчигт үнэ оруулахгүй байх. Үнийн дүнг заасан талбарт бичнэ.

2.17. Сайтын удирдлага буюу Компанийн эрх, үүрэг

    Хэрэглэгч буюу зар нийтлэгчийн өөрийнх нь бичиж найруулсан зарын утга агуулгад өөрчлөлт оруулахгүй байх.
    Хэрэв зарын утга агуулга нь Сайт дахь өөр нэг категорид илүү тохиромжтой бол зарын байршлыг өөрчлөн, тохиромжтой категорид нийтлэх эрхтэй.
    Хэрэв нийтлэгдсэн зар нь оруулсан категорид тохироогүй, Сайтын дотоод дүрмийг зөрчсөн бол устгах эрхтэй. Мөн нэг Хэрэглэгчээс нийтлэгдэх зарын тоог хязгаарлах эрхтэй.
    Зарлаж буй автомашины мэдээлэл хуурамч, байж боломгүй тохиолдолд зарыг хүчингүйд тооцно. Тухайн автомашины үнэ хэт худал үнэлэгдсэн байвал зар устгагдана. Автомашин, үйлчилгээний санал болгож буй үнээ үнэн зөв, бодитойгоор үнэлнэ.
    Зарын гарчиг нь зарын тайлбартай уялдаа холбоотой, утга нэгтэй байх ёстой. Гарчигт зарлагчийн холбоо барих утасны дугаар, хаяг бичигдэхгүй.
    Зарын зураг нь зарын гарчиг, зарын тайлбартай шууд холбоотой байх ёстой. Зурагт зөвхөн санал болгож буй автомашины дүрс байна. Интернет сайтаас хуулсан, чанарын шаардлага хангаагүй зургийг Сайт нийтлэхгүй.
    Хэрэглэгч хоорондын харилцаа холбоог хөнгөвчлөхийн тулд Сайт нь Хэрэглэгчийн холбоо барих хаяг, утасны дугаарын мэдээллийг бусад Хэрэглэгчдэд хязгаарлаж болно. Бусдын мэдээллийг ашиглах эрхийг энэхүү үйлчилгээний нөхцөлөөр зохицуулна.
    Хэрэглэгчийн бичсэн зарын утга агуулга, Сайтын холбоос, зарын тайлбарт Сайт хариуцлага үүрэхгүй.
    Хориотой бус, улсын хууль цаазад харшлаагүй, Сайтын хориотой барааны жагсаалтад ороогүй бүх автомашин, бараа бүтээгдэхүүн, үйлчилгээний зарыг нийтэлж болно.

2.18. Зарыг дараах шалтгаанаар устгана

Энэхүү нөхцөлийг Хэрэглэгч зөрчсөн гэж үзвэл Компани нь Хэрэглэгчийн нийтэлсэн зарыг доорх шалтгаанаар устгах эрхтэй:

    Компани нь эрх бүхий байгууллага болон холбогдох засгийн газрын байгууллагын шаардлагын дагуу зарыг устгах эрхтэй.
    Компани нь өөрийн үзсэнээр зарыг нийтийн ёс суртахуунд нийцэхгүй гэж үзвэл устгах эрхтэй.
    Тухайн Хэрэглэгчид нийтлэгдсэн автомашин, үйлчилгээтэй нь ижил төстэй зар байх.
    Зарын оруулсан мэдээлэл нь энэхүү үйлчилгээний нөхцөл, зар нийтлэх журам болон холбогдох хууль тогтоомж зөрчих.
    Зард агуулагдах мэдээлэл нь бодит бус байх.
    Зарын гарчиг нь зарын мэдээлэл тайлбартай холбогдолгүй байх.
    Зарын гарчигт олон тооны цэг таслал, анхааруулгын тэмдэг, том үсэг ашигласан байх.
    Зарын гарчиг болон тайлбарын зурагт интернет хаяг буюу бусад сайтын холбоос бичигдсэн байх.
    Зураг нь зарын утга агуулгатай нийцээгүй, шууд хамааралгүй байх.
    Зураг нь интернет сайтаас хуулсан, абстракт, үл ойлгогдох.
    Зарын гарчиг, тайлбар, зурагт ямар нэгэн хаяг оруулсан байх (бусад сайтын холбоос, имэйл, утасны дугаар, Skype, Facebook хаяг гэх мэт).
    Зургийн чанар муу, сурталчилж буй автомашин зураг дээрх бусад зүйлээс ялгарч харагдахгүй байх.
    Тухайн зарын категорид үл таарах.
    Хэрэглэгчдийн зүгээс болон бусад байгууллагаас оюуны өмчийн эрхийг зөрчсөн гэсэн гомдол ирэх.
    Бусад Хэрэглэгчдийн сурталчлах эрх зөрчигдсөн талаарх үндэслэлтэй гомдол ирэх.

III. Талуудын эрх үүрэг

3.1. Компанийн үйлчилгээг ашиглан нэвтрэх боломжтой дизайны элемент, текст, график, дүрслэл, видео, компьютерын программ, мэдээллийн бааз, дуу хөгжим, бусад объект, түүнчлэн үйлчилгээний цахим хуудсанд байрлуулсан агуулга нь Компанийн, Хэрэглэгчдийн болон бусад эрх эзэмшигчийн онцгой эрх болно.

3.2. Агуулга болон үйлчилгээний бусад элементүүдийг зөвхөн санал болгосон үйл ажиллагааны хүрээнд ашиглах боломжтой. Сайтад байрлуулсан ямар нэгэн агуулгыг зохиогчийн эрх эзэмшигчээс урьдчилан зөвшөөрөлгүйгээр ашиглаж болохгүй. Үүнд: дахин боловсруулах, хуулбарлах, түгээх зэрэг багтана.

3.3. Хэрэглэгчийн оруулсан мэдээллийг нийтлэх эрхийг Компанид олгох үүднээс Хэрэглэгч нь оруулсан мэдээллийн бүх агуулгын ашиглах, нийтлэх, хуулбарлах, хувилах, олон нийтийн мэдээлэл дамжуулах хэрэгслээр түгээх эрхийг Компанид хугацаагүй, буцалтгүй, онцгой бус хэлбэрээр олгож байна. Дээр дурдсан эрх нь ямар нэгэн цалин хөлс, урамшуулалгүйгээр үнэгүй олгогдоно. Энэ тохиолдолд зард байрлуулсан мэдээллийн өмчийн эрх нь Хэрэглэгчид хэвээр байна.

3.4. Сайтын үйлчилгээг ашигласнаар Хэрэглэгч нь өөрийн оруулсан зар дахь мэдээллийн хариуцлагыг дангаар үүрнэ.

3.5. Хэрэглэгчийн бичгээр өгсөн зөвшөөрлийн дагуу эсвэл холбогдох хууль тогтоомжийн дагуу Хэрэглэгчийн хувийн мэдээллийг гуравдагч этгээдэд олгож болно.

3.6. Энэхүү нөхцөлийг зөрчсөн дурын зарыг Компани нь буцаах, устгах эсвэл шилжүүлэх эрхтэй.

3.7. Хэрэглэгч нь Сайтын ажиллагаатай холбоотой санал гомдлоо “Санал хүсэлт, сэтгэгдэл” болон “Гомдол” хэсгүүдээр дамжуулан илэрхийлэх эрхтэй. Санал гомдолтой ажлын 2 өдрийн дотор танилцана.

3.8. Компани нь Хэрэглэгчийн оруулсан зар бүхий мэдээллийг холбогдох зургийн хамт Сайтын сурталчилгааны зорилгоор нийтлэх эрхтэй. Үүнд Хэрэглэгчийн зөвшөөрөл шаардагдахгүй бөгөөд ямар нэгэн мөнгөн урамшуулал Хэрэглэгчид олгогдохгүй.

3.9. Компани нь Хэрэглэгчийн оруулсан зар сурталчилгаа үнэн зөв болохыг нотлох баримтыг Хэрэглэгчээс шаардах эрхтэй. Тусгай зөвшөөрөлтэйгөөр эрхлэх үйл ажиллагааны хувьд Хэрэглэгч нь өөрийгөө, эсвэл бүтээгдэхүүнээ сурталчлахдаа тусгай зөвшөөрлийн гэрчилгээ буюу түүний зохих ёсоор баталгаажсан хуулбарыг Компанид үзүүлэх үүрэгтэй.

IV. Үйлчилгээний төлбөр

4.1. Хэрэглэгч нь төлбөрт үйлчилгээг Сайтаас болон аппликейшнаар дамжуулан авах боломжтой.

4.2. Хэрэглэгч нь төлбөрт үйлчилгээг захиалахаас өмнө төлбөрийн нөхцөлтэй анхлан танилцах үүрэгтэй.

4.3. Үйлчилгээний төлбөрийг Сайт дээр заасны дагуу хийнэ.

4.4. iOS аппликейшны (Apple® App Store® In-App Purchase) тусламжтай шилжүүлсэн мөнгөн дүн таны дансанд байрлах үед тухайн өдрийн Монголбанкны ханшнаас хамааран зөрүү үүсэж болохыг анхаарна уу.

V. Компанийн хариуцлагын хязгаарлалт

5.1. Сайтын үйлчилгээг ашигласнаар Хэрэглэгч нь өөрийн эрсдэлийг үнэлэн бүх эрсдэлийг үүрнэ. Хэрэглэгчдийн бичсэн зарын текстийн утга агуулга, Сайтын холбоос, зарын тайлбарт Компани хариуцлага үүрэхгүй.

5.2. Компани нь Хэрэглэгчдийн хоорондын худалдан авалтын зохион байгуулагч биш юм. Сайт нь Хэрэглэгчдийн хуулиар зөвшөөрөгдсөн автомашин, үйлчилгээ, барааг хүссэн үедээ, дурын газраас, хүссэн үнээр худалдах болон худалдан авах боломжийг бусад Хэрэглэгчдэд олгох зорилготой худалдааны харилцаа холбооны платформ юм.

5.3. Компани нь Хэрэглэгчдийн зард орсон мэдээллийн бодит байдлыг хянах боломжгүй. Компани нь хэлцэл хийгч талуудын аливаа нэгэн зохисгүй зан, эсвэл гарсан хохиролд хариуцлага хүлээхгүй.

5.4. Компани нь Хэрэглэгчийн зард санал болгож буй автомашин, бараа, үйлчилгээнд хариуцлага үүрэхгүй. Хэрэглэгчдийн хооронд гарсан маргаан, зөрчил нь Компанийн оролцоогүйгээр Хэрэглэгчдийн хооронд шийдвэрлэгдэнэ.

5.5. Гуравдагч этгээдийн хууль бусаар Сайтанд нэвтэрч Компанийн сервер болон Хэрэглэгчдийн мэдээллийг ашиглах, түүнчлэн Сайтаар дамжуулан вирус, Trojan гэх мэт тараасан тохиолдолд хариуцлага хүлээхгүй.

5.6. Зард байршсан автомашин, бараа, үйлчилгээний чанар, аюулгүй байдал, хуулиар зөвшөөрөгдсөн байдал болон тайлбартайгаа нийцэх асуудал Компанийн хяналтаас гадуур байна.

5.7. Хэрэглэгч Сайтыг ашиглаж байх үедээ анхаарал болгоомжтой байхыг Компани зөвлөж байна. Зарын эзэн нь дурдсан чадваргүй эсвэл өөр хүний дүр эсгэж байж болохыг анхаарна уу. Хэрэглэгч Сайтыг хэрэглэснээр дээрх эрсдэлийг болон бусад Хэрэглэгчийн үйлдэлд Компани хариуцлага үүрэхгүй болохыг хүлээн зөвшөөрнө.

5.8. Сайтын Хэрэглэгч нь өөрийн хийсэн үйлдэлд бүрэн хариуцлага үүрнэ.

5.9. Хэрэглэгчийн эрх ашгийг өөр нэгэн Хэрэглэгч зөрчсөн тохиолдлыг Хэрэглэгч Компанид мэдэгдэх эрхтэй. Хэрэглэгчийн гомдол бодит болох нь тогтоогдвол Компани нь өөрийн үзсэнээр холбогдох зарыг устгах эрхтэй.

5.10. Сайтыг буруу хэрэглэснээс гарсан хохирлыг Компани хариуцахгүй.

VI. Үйлчилгээний нөхцөлийн хамрах хугацаа

6.1. Энэхүү нөхцөлийг Хэрэглэгч Сайт ашиглаж эхэлсэн үеэс мөрдөх бөгөөд энэхүү нөхцөл нь хугацаагүй.

6.2. Хэрэглэгч нь өөрийн бүртгэлийг Сайтаас Компанид урьдчилан мэдэгдэлгүй, тайлбар өгөлгүй хасах эрхтэй.

6.3. Хэрэв Компани VII бүлэгт заасанчлан үйлчилгээний нөхцөлд нэмэлт өөрчлөлт оруулсан аль нэг заалтыг Хэрэглэгч хүлээн зөвшөөрөхгүй бол Хэрэглэгч Сайтын үйлчилгээг ашиглахаа дуусгавар болгох үүрэгтэй. Хэрэглэгч Сайтыг ашигласан хэвээр бол нэмэлт өөрчлөлтийг хүлээн зөвшөөрсөнд тооцно.

VII. Үйлчилгээний нөхцөлийн нэмэлт өөрчлөлт

7.1. Компани нь урьдчилан мэдэгдэлгүйгээр үйлчилгээний нөхцөлд нэмэлт өөрчлөлт оруулж болно. Үйлчилгээний нөхцөлийн шинэ хувилбар доорх интернет хаяг дээр байршсан өдрөөс эхлэн мөрдөгдөнө. Үйлчилгээний нөхцөлийн хүчин төгөлдөр найруулга [Сайтын албан ёсны URL]-д байрлана.

VIII. Нэг автомашины дүрэм

    Нэг зард зөвхөн нэг автомашин, бараа эсвэл үйлчилгээ оруулна.
    Зарын хугацаа (30 хоног) дуусаагүй үед түүнийг устган шинээр ижил зар оруулах нь зар давхацсанд тооцно.

Анхааруулга: Энэхүү үйлчилгээний нөхцөлийг сайтар уншиж танилцан, дагаж мөрдөхийг хүсье. Хэрэв танд асуулт, санал, гомдол байвал бидэнтэй холбоо барина уу.
`}
              </pre>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Footer;
