// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../App.css';
import { useAuth } from '../contexts/AuthContext';
import { AiOutlineHeart, AiOutlineClose } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { BsChatDots } from 'react-icons/bs';
import { MdKeyboardArrowDown } from 'react-icons/md';
import LanguageSwitcher from './LanguageSwitcher';
import { getChats } from '../utils/db';

const Header = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [savedCount, setSavedCount] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const savedCars = JSON.parse(localStorage.getItem('savedCars')) || {};
    setSavedCount(Object.keys(savedCars).length);

    if (currentUser) {
      fetchUnreadMessageCount();
    }
  }, [currentUser, location.pathname]);

  const fetchUnreadMessageCount = async () => {
    try {
      const chats = await getChats();
      console.log('Chats:', chats); // Add this line for debugging
      const totalUnread = Array.isArray(chats) 
        ? chats.reduce((sum, chat) => sum + (chat.unread_count || 0), 0)
        : 0;
      setUnreadMessageCount(totalUnread);
    } catch (error) {
      console.error('Error fetching unread message count:', error);
    }
  };


  async function handleLogout() {
    try {
      await logout();
      navigate('/');
    } catch {
      console.error("Failed to log out");
    }
  }

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const mobileMenuItems = [
    { title: 'Бүх Зарууд', link: '/listings' },
    // { title: 'New Cars', link: '/new-cars' },
    { title: 'Машинаа Зарах', link: '/sell-my-car' },
    // { title: 'Car Auction', link: '/car-auction' },
    // { title: 'Market Insight', link: '/market-insight' },
  ];

  return (
    <header className="header">
      <div className="desktop-header">
        <div className="logo">
          <Link to="/">softmax</Link>
        </div>
        <nav>
          <ul>
            <li><Link to="/listings">Бүх Зарууд</Link></li>
            {/* <li><Link to="/new-cars">New Cars</Link></li> */}
            <li><Link to="/sell-my-car">Машинаа Зарах</Link></li>
            {/* <li><Link to="/car-auction">Car Auction</Link></li>
            <li><Link to="/market-insight">Market Insight</Link></li> */}
          </ul>
        </nav>
        <div className="auth-buttons">
          {currentUser ? (
            <div className="icon-container">
              <Link to="/saved" className="icon-link">
                <AiOutlineHeart size={24} />
                <span>Хадгалсан ({savedCount})</span>
              </Link>
              <Link to="/chat" className="icon-link">
              <BsChatDots size={24} />
              <span>Чат {unreadMessageCount > 0 && `(${unreadMessageCount})`}</span>
            </Link>
              <Link to="/account" className="icon-link">
                <FiUser size={24} />
                <span>Хаяг</span>
              </Link>
            </div>
          ) : (
            <>
              <Link to="/login"><button className="sign-in">Нэвтрэх</button></Link>
              <Link to="/signup"><button className="sign-up">Бүртгүүлэх</button></Link>
            </>
          )}
        </div>
      </div>

      <div className="mobile-header">
        <div className="mobile-logo">
          <Link to="/">softmax</Link>
        </div>
        <div className="mobile-buttons">
          <Link to="/signup"><button className="mobile-sign-up">Sign up</button></Link>
          <button className="mobile-menu-button" onClick={toggleMobileMenu}>
            {mobileMenuOpen ? <AiOutlineClose size={24} /> : <span>☰</span>}
          </button>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="mobile-menu">
          {mobileMenuItems.map((item, index) => (
            <div key={index} className="mobile-menu-item">
              <Link to={item.link} onClick={toggleMobileMenu}>
                {item.title}
              </Link>
              <MdKeyboardArrowDown size={24} />
            </div>
          ))}
          <div className="mobile-auth-buttons">
            <Link to="/signup" onClick={toggleMobileMenu}>
              <button className="mobile-sign-up">Sign up</button>
            </Link>
            <Link to="/login" onClick={toggleMobileMenu}>
              <button className="mobile-sign-in">Sign in</button>
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;