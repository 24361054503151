import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import App from './App';
import './index.css';

import { api } from './contexts/AuthContext';

api.get('/csrf-token/')
  .then(response => {
    console.log('CSRF token set');
  })
  .catch(error => {
    console.error('Error fetching CSRF token:', error);
  });

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);