// src/components/EditListing.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography, Box, Grid, Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Chip, IconButton
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const EditListing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [carDetails, setCarDetails] = useState(null);

  useEffect(() => {
    // Fetch the car details and set them in the state
    const savedListings = JSON.parse(localStorage.getItem('carListings')) || [];
    const foundCar = savedListings.find(car => car.id === id);
    if (foundCar && foundCar.sellerId === currentUser.uid) {
      setCarDetails(foundCar);
    } else {
      navigate('/'); // Redirect if not the owner
    }
  }, [id, currentUser, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCarDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const listings = JSON.parse(localStorage.getItem('carListings')) || [];
    const updatedListings = listings.map(car => 
      car.id === id ? { ...car, ...carDetails } : car
    );
    localStorage.setItem('carListings', JSON.stringify(updatedListings));
    navigate(`/car/${id}`);
  };

  if (!carDetails) return <div>Loading...</div>;

  return (
    <form onSubmit={handleSubmit}>
      {/* Add form fields similar to SellMyCar component, but pre-filled with carDetails */}
      {/* ... */}
      <Button type="submit">Update Listing</Button>
    </form>
  );
};

export default EditListing;