// src/components/PasswordReset.js

import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { TextField, Button, Typography, Container, Box, Alert } from '@mui/material';

export default function PasswordReset() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { resetPassword } = useAuth();

  // Email validation function
  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    try {
      await resetPassword(email);
      setMessage('Нууц үг шинэчлэх холбоос таны и-мэйл хаяг руу илгээгдлээ.');
    } catch (err) {
      // Optionally, you can display specific error messages based on the backend response
      setError(err.message || 'Нууц үг шинэчлэх үйл явц амжилтгүй боллоо.');
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Нууц үг сэргээх
        </Typography>
        {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
        {message && <Alert severity="success" sx={{ width: '100%', mt: 2 }}>{message}</Alert>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="И-мэйл хаяг"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!!message} // Disable input after successful request
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!email || !!message} // Disable button if email is empty or after success
          >
            Нууц үг сэргээх
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
