// src/components/Lines.js
import React from "react";
import { motion } from "framer-motion";

const Lines = () => {
  const width = 1500;
  const height = 300;
  const count = 8;

  const inceptionTexts = ["", "", "", "", "", "", "", ""];
  return (
    <div className="lines-section">
      <svg
        width="95%" // Full width
        height="100%" // Full height
        viewBox={`0 0 ${width} ${height}`} // Updated viewBox
        preserveAspectRatio="xMidYMid meet" // Maintain aspect ratio with centering
      >
        <defs>
          <mask id="curveMask">
            {Array.from({ length: count }).map((_, i) => {
              const x = i * (width / (count - 1));
              return (
                <path
                  key={i}
                  // Adjusted the control points to stretch the lines downward
                  d={`M${x},0 C${x},${height * 0.75} ${width / 2},${height * 0.5} ${width / 2},${height}`}
                  stroke="white"
                  strokeWidth="1.5"
                  fill="none"
                />
              );
            })}
          </mask>
          <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
            <motion.stop
              stopColor="rgba(0,0,0,0.5)"
              animate={{ offset: ["-150%", "100%"] }}
              transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
            />
            <motion.stop
              stopColor="transparent"
              animate={{ offset: ["-20%", "100%"] }}
              transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
            />
            <motion.stop
              stopColor="aquamarine"
              animate={{ offset: ["-12%", "108%"] }}
              transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
            />
            <motion.stop
              stopColor="rgba(0,0,0,0.5)"
              animate={{ offset: ["-8%", "112%"] }}
              transition={{ duration: 3, repeat: Infinity, ease: "easeInOut" }}
            />
          </linearGradient>

          {/* Gradient for the text */}
          <linearGradient id="textGradient" x1="0" x2="1" y1="0" y2="0">
            <stop offset="0%" stopColor="#0044cc" /> 
            <stop offset="50%" stopColor="blue" />
            <stop offset="100%" stopColor="#00aaff" />
          </linearGradient>
        </defs>
        <g mask="url(#curveMask)">
          <rect x="0" y="0" width={width} height={height} fill="#333" />
          <rect x="0" y="0" width={width} height={height} fill="url(#gradient)" />
        </g>

         {/* Text at the intersection */}
         <text
          x="50%"
          y={`${height}`} // Positioning text closer to the intersection
          textAnchor="middle"
          fill="url(#textGradient)"
          fontSize="48" // Adjust font size as needed
          fontWeight="bold"
        >
          softmax
        </text>

        {/* Text at the inception of each line */}
        {inceptionTexts.map((text, i) => {
                    const x =
                    i === 0
                      ? 10 // Offset for the first text
                      : i === count - 1
                      ? width - 10 // Offset for the last text
                      : i * (width / (count - 1));
          return (
            <text
              key={i}
              x={x}
              y="15" // Position text near the top (adjust as needed)
              textAnchor="middle"
              fill="url(#textGradient)"
              fontSize="20" // Smaller font size
              fontWeight="bold"
            >
              {text}
            </text>
          );
        })}
      </svg>
    </div>
  );
};

export default Lines;

