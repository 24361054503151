// src/components/SellerProfile.js

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Grid, Card, CardContent, Box, Container } from '@mui/material';
import { getCarListings, getUserInfo } from '../utils/db';
import { getTimeSincePosted } from '../utils/timeUtils';

const SellerProfile = () => {
    const { sellerId } = useParams();
    const [seller, setSeller] = useState(null);
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchSellerData = async () => {
        try {
          const sellerInfo = await getUserInfo(sellerId);
          setSeller(sellerInfo);
  
          const allListings = await getCarListings();
          const sellerListings = allListings.results.filter(car => car.owner === parseInt(sellerId));
          setListings(sellerListings);
        } catch (error) {
          console.error('Error fetching seller data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchSellerData();
    }, [sellerId]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!seller) {
    return <Typography>Seller not found</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>{seller.username}'s Listings</Typography>
      <Grid container spacing={3}>
        {listings.map((car) => (
          <Grid item xs={12} sm={6} md={4} key={car.id}>
            <Link to={`/car/${car.id}`} style={{ textDecoration: 'none' }}>
              <Card>
                <CardContent>
                  <Typography variant="h6">{car.yearMade} {car.make} {car.model}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {getTimeSincePosted(car.datePosted)}
                  </Typography>
                  <Typography variant="h5">${Number(car.price).toLocaleString()}</Typography>
                  <Box mt={1}>
                    <Typography variant="body2">{car.odometer} km • {car.gearbox} • {car.fuelType}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SellerProfile;