// src/components/Profile.js
import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Typography, Button, Container, Box } from '@mui/material';

export default function Profile() {
  const { currentUser, verifyEmail } = useAuth();

  async function handleVerifyEmail() {
    try {
      await verifyEmail();
      alert("Verification email sent!");
    } catch (error) {
      console.error("Error sending verification email", error);
      alert("Failed to send verification email");
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Profile
        </Typography>
        <Typography>
          Email: {currentUser.email}
        </Typography>
        <Typography>
          Email verified: {currentUser.emailVerified ? "Yes" : "No"}
        </Typography>
        {!currentUser.emailVerified && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerifyEmail}
            sx={{ mt: 3, mb: 2 }}
          >
            Verify Email
          </Button>
        )}
      </Box>
    </Container>
  );
}