// VerifyEmail.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Container, Box, CircularProgress } from '@mui/material';
import { verifyEmail } from '../utils/db';
import { useAuth } from '../contexts/AuthContext';

export default function VerifyEmail() {
  const [status, setStatus] = useState('verifying');
  const { token } = useParams();
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const verify = async () => {
      try {
        const response = await verifyEmail(token);
        console.log('Verification response:', response);
        setStatus('success');
        // Log the user in automatically
        await login(response.email, null, response.token);
        setTimeout(() => navigate('/'), 3000);
      } catch (error) {
        console.error('Verification error:', error);
        setStatus('error');
      }
    };
    verify();
  }, [token, navigate, login]);

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {status === 'verifying' && (
          <>
            <CircularProgress />
            <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
              Verifying your email...
            </Typography>
          </>
        )}
        {status === 'success' && (
          <Typography component="h1" variant="h5">
            Email verified successfully! Redirecting to home page...
          </Typography>
        )}
        {status === 'error' && (
          <Typography component="h1" variant="h5" color="error">
            Failed to verify email. The link may be invalid or expired.
          </Typography>
        )}
      </Box>
    </Container>
  );
}
