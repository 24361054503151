// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { TextField, Button, Typography, Container, Box, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

export default function Login() {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [lockoutTime, setLockoutTime] = useState(0); // In seconds
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
    }
  }, [location]);

  useEffect(() => {
    // Check if there's a lockout end time in local storage
    const lockoutEndTime = localStorage.getItem('lockoutEndTime');
    if (lockoutEndTime) {
      const remainingTime = Math.floor((new Date(lockoutEndTime) - new Date()) / 1000);
      if (remainingTime > 0) {
        setLockoutTime(remainingTime);
      } else {
        localStorage.removeItem('lockoutEndTime');
      }
    }
  }, []);

  useEffect(() => {
    let timer;
    if (lockoutTime > 0) {
      timer = setInterval(() => {
        setLockoutTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            localStorage.removeItem('lockoutEndTime');
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [lockoutTime]);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await login(identifier, password);
      navigate('/');
    } catch (error) {
      if (error.message.includes('locked')) {
        // Extract remaining time from the error message
        const match = error.message.match(/(\d+) minutes and (\d+) seconds/);
        let totalSeconds = 600; // Default to 10 minutes
        if (match) {
          const minutes = parseInt(match[1], 10);
          const seconds = parseInt(match[2], 10);
          totalSeconds = minutes * 60 + seconds;
        }
        setLockoutTime(totalSeconds);
        // Store the lockout end time in local storage
        const lockoutEndTime = new Date(new Date().getTime() + totalSeconds * 1000);
        localStorage.setItem('lockoutEndTime', lockoutEndTime.toISOString());

        setError('Your account is locked due to multiple failed login attempts. Please try again later.');
      } else {
        setError('Failed to log in');
      }
    }
  }

  // Function to format time in MM:SS
  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${m}:${s}`;
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Нэвтрэх
        </Typography>
        {message && <Alert severity="info" sx={{ width: '100%', mt: 2 }}>{message}</Alert>}
        {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
        {lockoutTime > 0 && (
          <Alert severity="warning" sx={{ width: '100%', mt: 2 }}>
            Your account is locked for {formatTime(lockoutTime)} minutes.
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="identifier"
            label="Имэйл эсвэл Утасны дугаар"
            name="identifier"
            autoComplete="email"
            autoFocus
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
            disabled={lockoutTime > 0}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Нууц үг"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={lockoutTime > 0}
          />
          <Link to="/reset-password" style={{ alignSelf: 'flex-end', marginTop: '8px' }}>
            Нууц үгээ мартсан уу?
          </Link>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={lockoutTime > 0}
          >
            Нэвтрэх
          </Button>
        </Box>
      </Box>
    </Container>
  );
}