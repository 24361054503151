// src/utils/timeUtils.js

export const getTimeSincePosted = (datePosted) => {
  if (!datePosted) return 'Тодорхойгүй'; // "Unknown" in Mongolian

  const now = new Date();
  const postedDate = new Date(datePosted);
  const diffInMilliseconds = now - postedDate;
  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  if (diffInMinutes < 60) {
    return `${diffInMinutes} минутын өмнө`; // "minutes ago" in Mongolian
  } else if (diffInHours < 24) {
    return `${diffInHours} цагийн өмнө`; // "hours ago" in Mongolian
  } else {
    return `${diffInDays} хоногийн өмнө`; // "days ago" in Mongolian
  }
};