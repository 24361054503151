// src/components/ChatInterface.js

import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress, Typography, Chip, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { getChats, sendMessage, getChatMessages, markMessagesAsRead } from '../utils/db';
import '../styles/ChatInterface.css';

const ChatInterface = () => {
  const { currentUser } = useAuth();
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchChats = async () => {
      if (currentUser) {
        try {
          setLoading(true);
          const userChats = await getChats();
          console.log('Fetched chats:', userChats);
          setChats(Array.isArray(userChats) ? userChats : []);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching chats:', error);
          setError('Failed to fetch chats. Please try again later.');
          setLoading(false);
        }
      }
    };
    fetchChats();
  }, [currentUser]);

  useEffect(() => {
    if (selectedChat) {
      fetchMessages();
      handleMarkMessagesAsRead(selectedChat.id);
    }
  }, [selectedChat]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const fetchMessages = async () => {
    try {
      console.log('Fetching messages for chat:', selectedChat.id);
      const chatMessages = await getChatMessages(selectedChat.id);
      console.log('Fetched messages:', chatMessages);
      let sortedMessages = Array.isArray(chatMessages) ? chatMessages : 
                           (chatMessages && chatMessages.results ? chatMessages.results : []);
      
      // Sort messages by timestamp
      sortedMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      
      setMessages(sortedMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError('Failed to fetch messages. Please try again later.');
    }
  };

  const handleMarkMessagesAsRead = async (chatId) => {
    try {
      await markMessagesAsRead(chatId);
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim() && selectedChat) {
      try {
        const newMessage = await sendMessage(selectedChat.id, message);
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages, newMessage];
          // Sort messages by timestamp
          updatedMessages.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
          return updatedMessages;
        });
        setMessage('');
        // ... other actions after sending message
      } catch (error) {
        console.error('Error sending message:', error);
        setError('Failed to send message. Please try again.');
      }
    }
  };

  // Removed extractHeaderInfo function as it's no longer needed

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div className="chat-container">
      <div className="chat-list">
        {chats.map((chat) => (
          <div 
            key={chat.id} 
            className={`chat-list-item ${selectedChat && selectedChat.id === chat.id ? 'selected' : ''}`}
            onClick={() => setSelectedChat(chat)}
          >
            <div className="chat-list-item-name">
              {chat.other_participant?.username || 'Unknown User'}
              {chat.unread_count > 0 && <span className="unread-indicator" />}
            </div>
            <div className="chat-list-item-preview">{chat.last_message || 'No messages yet'}</div>
          </div>
        ))}
      </div>
      <div className="chat-main">
        {selectedChat ? (
          <>
            <div className="chat-header">
              <Typography variant="h6">{selectedChat.other_participant?.username || 'Unknown User'}</Typography>
              {selectedChat.other_participant && (
                <Box className="chat-header-info" sx={{ display: 'flex', gap: '8px', mt: 1 }}>
                  {selectedChat.other_participant.email && (
                    <Chip label={`Email: ${selectedChat.other_participant.email}`} className="info-chip" />
                  )}
                  {selectedChat.other_participant.phone_number && (
                    <Chip label={`Phone: ${selectedChat.other_participant.phone_number}`} className="info-chip" />
                  )}
                  {selectedChat.other_participant.location && (
                    <Chip label={`Location: ${selectedChat.other_participant.location}`} className="info-chip" />
                  )}
                </Box>
              )}
            </div>
            <div className="chat-messages">
              {messages.map((msg, index) => {
                const messageDate = new Date(msg.timestamp);
                const showDate = index === 0 || 
                  messageDate.toDateString() !== new Date(messages[index - 1].timestamp).toDateString();

                return (
                  <React.Fragment key={msg.id}>
                    {showDate && (
                      <div className="date-separator">
                        {messageDate.toLocaleDateString()}
                      </div>
                    )}
                    <div 
                      className={`message ${msg.sender === currentUser.id ? 'message-sender' : 'message-receiver'}`}
                    >
                      <div className="message-content">{msg.content}</div>
                      <div className="message-timestamp">
                        {messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
              <div ref={messagesEndRef} />
            </div>
            <div className="chat-input">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message"
                onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              />
              <button onClick={handleSendMessage}>Send</button>
            </div>
          </>
        ) : (
          <Typography className="chat-placeholder">Select a chat to start messaging</Typography>
        )}
      </div>
    </div>
  );
};

export default ChatInterface;
