// src/components/SavedCars.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid, Card, CardContent, Container, CircularProgress, IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { getTimeSincePosted } from '../utils/timeUtils';
import { getCarListings } from '../utils/db';
import ImageCarousel from './ImageCarousel';

const SavedCars = () => {
  const [savedCars, setSavedCars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSavedCars = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const savedCarIds = JSON.parse(localStorage.getItem('savedCars')) || {};
        const response = await getCarListings();

        let allListings = Array.isArray(response) ? response : (response?.results || []);
        const savedCarListings = allListings.filter(car => savedCarIds[car.id]);

        setSavedCars(savedCarListings);
      } catch (error) {
        console.error('Error fetching saved cars:', error);
        setError('Failed to fetch saved cars. Please try again later.');
      }
      setIsLoading(false);
    };

    fetchSavedCars();
  }, []);

  const removeSavedCar = (carId) => {
    const savedCarIds = JSON.parse(localStorage.getItem('savedCars')) || {};
    delete savedCarIds[carId];
    localStorage.setItem('savedCars', JSON.stringify(savedCarIds));
    setSavedCars(prev => prev.filter(car => car.id !== carId));
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Хадгалсан Машинууд
      </Typography>
      {savedCars.length === 0 ? (
        <Typography>Одоогоор хадгалсан машин байхгүй байна.</Typography>
      ) : (
        <Grid container spacing={3}>
          {savedCars.map((car) => (
            <Grid item xs={12} sm={6} md={4} key={car.id}>
              <Card>
                <ImageCarousel 
                  images={car.images && car.images.length > 0 
                    ? car.images.map(img => img.image || img)
                    : ['https://via.placeholder.com/300x169']}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {car.yearMade} {car.make} {car.model}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    ₮{car.price ? car.price.toLocaleString() : 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {car.odometer ? `${car.odometer.toLocaleString()} км` : 'Mileage not available'}
                  </Typography>
                  {car.datePosted && (
                    <Typography variant="body2" color="text.secondary">
                      Нийтлэгдсэн: {getTimeSincePosted(car.datePosted)}
                    </Typography>
                  )}
                  <IconButton onClick={() => removeSavedCar(car.id)}>
                    <FavoriteIcon color="error" />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default SavedCars;