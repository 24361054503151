// src/components/ProfilePage.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { Typography, Box, Container, Button, CircularProgress, Grid, Card, CardContent } from '@mui/material';
import { getUserInfo, getCarListings } from '../utils/db';
import { getTimeSincePosted } from '../utils/timeUtils';

const ProfilePage = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [userListings, setUserListings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const info = await getUserInfo();
        setUserInfo(info);

        const listings = await getCarListings();
        const userListings = listings.results.filter(car => car.owner === currentUser.id);
        setUserListings(userListings);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch {
      console.error("Failed to log out");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!currentUser) {
    return (
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1" gutterBottom>
          Та нэвтрээгүй байна
        </Typography>
        <Link to="/login">Нэвтрэх</Link>
      </Container>
    );
  }

  return (
    <div className="profile-page">
      <Container maxWidth="lg" className="content-container">
        <Box className="account-info-section">
          <Typography variant="h4" component="h1" gutterBottom className="section-title">
            Хэрэглэгчийн мэдээлэл
          </Typography>
          <div className="info-container">
            <div className="info-item">
              <span className="info-label">Нэр:</span>
              <span className="info-value">{currentUser.username || 'Тохируулаагүй'}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Имэйл:</span>
              <span className="info-value">{currentUser.email}</span>
              {currentUser.is_phone_verified && <span className="Баталгаажсан">Verified</span>}
            </div>
            <div className="info-item">
              <span className="info-label">Утасны Дугаар:</span>
              <span className="info-value">{currentUser.phone_number || 'Тохируулаагүй'}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Байршил:</span>
              <span className="info-value">{currentUser.location || 'Тохируулаагүй'}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Боломжит зар нийтлэх эрх:</span>
              <span className="info-value">{userInfo ? userInfo.available_listing_slots : 'Уншиж байна...'}</span>
            </div>
          </div>
          <div className="button-container">
            <button className="profile-button logout-button" onClick={handleLogout}>ГАРАХ</button>
          </div>
        </Box>
      </Container>

      <div className="your-listings-section">
        <Container maxWidth="lg" className="content-container">
          <Typography variant="h4" component="h2" gutterBottom className="section-title">
            Таны Зарууд
          </Typography>
          {userListings.length === 0 ? (
            <Typography variant="body1">Одоогоор оруулсан зар байхгүй байна.</Typography>
          ) : (
            <Grid container spacing={3}>
              {userListings.map((car) => (
                <Grid item xs={12} md={6} key={car.id}>
                  <Link to={`/car/${car.id}`} className="car-link">
                    <Card className="car-card">
                      <CardContent>
                        <Typography variant="h6" component="div" className="car-title">
                          {car.yearMade} {car.make} {car.model}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          ({getTimeSincePosted(car.datePosted)})
                        </Typography>
                        <Typography variant="h5" className="car-price">
                          ₮{car.price ? car.price.toLocaleString() : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" className="car-details">
                          {car.odometer} км | {car.engine} | {car.gearbox} | {car.fuelType}
                        </Typography>
                        <Box className="dealer-info">
                          <Typography variant="body2">{car.views || 0} үзсэн • {car.saves || 0} хадгалсан</Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
          <Link to="/sell-my-car" className="list-car-link">
            <button className="profile-button list-car-button">Зар нийтлэх</button>
          </Link>
        </Container>
      </div>
    </div>
  );
};

export default ProfilePage;