// src/components/ContactSellerForm.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { createOrGetChat, sendMessage } from '../utils/db';
import { useAuth } from '../contexts/AuthContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Rubik, Arial, sans-serif',
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          fontFamily: 'Rubik, Arial, sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Rubik, Arial, sans-serif',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Rubik, Arial, sans-serif',
        },
      },
    },
  },
});

const BlackButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'black',
  color: 'white',
  '&:hover': {
    backgroundColor: '#333',
  },
}));

const ContactSellerForm = ({ open, onClose, car }) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('Check availability');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!currentUser) {
      navigate('/login');
      return;
    }
  
    try {
      const chat = await createOrGetChat(car.owner);
      
      // Send header information as a separate message
      const headerInfo = `From: ${name}\nEmail: ${email}\nPhone: ${phone}`;
      await sendMessage(chat.id, headerInfo);
      
      // Send the actual message
      const messageContent = `Subject: ${subject}\n\n${message}`;
      await sendMessage(chat.id, messageContent);
  
      onClose();
      navigate('/chat', { state: { selectedChat: chat } });
    } catch (error) {
      console.error('Error creating chat or sending message:', error);
      alert(`Error: ${error.response?.data?.error || error.message}`);
    }
  };


  return (
    <ThemeProvider theme={theme}>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Зарын эзэнтэй холбогдох
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Нэр"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Имэйл"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Утасны дугаар"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Select
              fullWidth
              margin="normal"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            >
              <MenuItem value="Check availability">Зарагдсан үгүйг лавлах</MenuItem>
              <MenuItem value="Request more info">Нэмэлт мэдээлэл тодруулах</MenuItem>
              <MenuItem value="Schedule test drive">Очиж үзэх цаг тохирох</MenuItem>
            </Select>
            <TextField
              fullWidth
              margin="normal"
              label="Нэмэлт асуулт"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <BlackButton
              type="submit"
              variant="contained"
              fullWidth
              sx={{ 
                mt: 2, 
                backgroundColor: 'black !important', 
                color: 'white !important',
                '&:hover': {
                  backgroundColor: '#333 !important',
                }
              }}
            >
              Мэссэж Илгээх
            </BlackButton>
            <Typography variant="caption" display="block" sx={{ mt: 1 }}>
              Энд дарснаар та энэхүү зарыг эзнийг таны хүслийн дагуу өөртэй чинь холбоо барихыг хүлээн зөвшөөрч байгаа болно.
            </Typography>
          </form>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

export default ContactSellerForm;