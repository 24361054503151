// src/components/CarListings.js

import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Grid, Card, CardContent, Typography, TextField, 
  Select, MenuItem, Button, Box, Chip, Checkbox, FormControlLabel,
  Container, IconButton, Drawer, Pagination
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FilterListIcon from '@mui/icons-material/FilterList';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { carData, carMakes } from '../data/carData';
import { getTimeSincePosted } from '../utils/timeUtils';
import '../styles/CarListings.css';
import { getCarListings } from '../utils/db';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-family: "Rubik", "Noto Sans Mongolian", sans-serif;
        }
      `,
    },
  },
});

const ImageCarousel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const handlePrev = (e) => {
    e.preventDefault();
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = (e) => {
    e.preventDefault();
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '200px',
        overflow: 'hidden',
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <img
        src={images[currentImageIndex]}
        alt={`Car image ${currentImageIndex + 1}`}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />
      {isHovering && images.length > 1 && (
        <>
          <IconButton
            onClick={handlePrev}
            sx={{
              position: 'absolute',
              left: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: 8,
              top: '50%',
              transform: 'translateY(-50%)',
              color: 'white',
              backgroundColor: 'rgba(0,0,0,0.5)',
              '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' },
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </>
      )}
      <Box
        sx={{
          position: 'absolute',
          bottom: 8,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: '4px',
        }}
      >
        {images.slice(0, 4).map((_, index) => (
          <Box
            key={index}
            sx={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: index === currentImageIndex ? 'white' : 'rgba(255,255,255,0.5)',
            }}
          />
        ))}
      </Box>
      {images.length > 1 && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 8,
            right: 8,
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: 'white',
            padding: '2px 6px',
            borderRadius: '4px',
            fontSize: '12px',
          }}
        >
          {currentImageIndex + 1}/{images.length}
        </Box>
      )}
    </Box>
  );
};

const CarListings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [make, setMake] = useState('');
  const [error, setError] = useState(null);
  const [model, setModel] = useState('');
  const [yearMin, setYearMin] = useState('');
  const [yearMax, setYearMax] = useState('');
  const [priceMin, setPriceMin] = useState('');
  const [priceMax, setPriceMax] = useState('');
  const [mileageMin, setMileageMin] = useState('');
  const [mileageMax, setMileageMax] = useState('');
  const [sort, setSort] = useState('');
  const WILL_BE_SHIPPED_FILTER = {
    display: 'Ачигдах Машинууд',
    key: 'Will be shipped'
  };
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [includeWillBeShipped, setIncludeWillBeShipped] = useState(true);
  const [locationFilter, setLocationFilter] = useState();
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [savedCars, setSavedCars] = useState({});

  useEffect(() => {
    // Load saved cars from localStorage
    const savedCarsFromStorage = JSON.parse(localStorage.getItem('savedCars')) || {};
    setSavedCars(savedCarsFromStorage);

    const fetchListings = async () => {
      setLoading(true);
      try {
        const response = await getCarListings(page);
        console.log('API response:', response);
  
        let savedListings = [];
        if (response && response.results && Array.isArray(response.results)) {
          savedListings = response.results;
          setTotalPages(Math.ceil(response.count / 25));
        } else if (Array.isArray(response)) {
          savedListings = response;
          setTotalPages(Math.ceil(response.length / 25));
        } else {
          console.error('Unexpected format for savedListings:', response);
          savedListings = [];
        }
  
        // Process listings to include full image URLs
        const listingsWithFullImageUrls = savedListings.map(listing => ({
          ...listing,
          images: listing.images.map(image => 
            image.image.startsWith('http') 
              ? image.image 
              : `${process.env.REACT_APP_API_URL}${image.image}`
          )
        }));
  
        const searchParams = new URLSearchParams(location.search);
        const makeParam = searchParams.get('make');
        const modelParam = searchParams.get('model');
        const yearMinParam = searchParams.get('yearMin');
        const yearMaxParam = searchParams.get('yearMax');
        const locationParam = searchParams.get('location');
  
        if (makeParam) {
          setMake(makeParam);
          setSelectedFilters(prev => [...prev, makeParam]);
        }
        if (modelParam) setModel(modelParam);
        if (yearMinParam) setYearMin(yearMinParam);
        if (yearMaxParam) setYearMax(yearMaxParam);
        if (locationParam) setLocationFilter(locationParam);
  
        // Filter listings based on URL parameters
        const filteredListings = listingsWithFullImageUrls.filter(car => 
          (!makeParam || car.make === makeParam) &&
          (!modelParam || car.model === modelParam) &&
          (!yearMinParam || car.yearMade >= parseInt(yearMinParam)) &&
          (!yearMaxParam || car.yearMade <= parseInt(yearMaxParam)) &&
          (!locationParam || car.sellerLocation === locationParam)
        );
  
        setListings(filteredListings);
      } catch (error) {
        console.error('Error fetching listings:', error);
        setError('Failed to fetch listings. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchListings();
  }, [location.search, page]);

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };
  
  const formatPrice = (price) => {
    if (!price) return 'N/A';
    return parseInt(price).toLocaleString('en-US');
  };

  const handleLocationChange = (event) => {
    setLocationFilter(event.target.value);
  };
  const toggleSave = (e, carId) => {
    e.preventDefault(); // Prevent navigation to car detail page
    setSavedCars(prev => {
      const newSavedCars = { ...prev, [carId]: !prev[carId] };
      localStorage.setItem('savedCars', JSON.stringify(newSavedCars));
      return newSavedCars;
    });
  };

  const handleFilterRemove = (filter) => {
    setSelectedFilters(selectedFilters.filter(f => f !== filter));
    if (carMakes.includes(filter)) {
      setMake('');
    } else if (filter.startsWith('Min Price:')) {
      setPriceMin('');
    } else if (filter.startsWith('Max Price:')) {
      setPriceMax('');
    } else if (filter.startsWith('Min Mileage:')) {
      setMileageMin('');
    } else if (filter.startsWith('Max Mileage:')) {
      setMileageMax('');
    }
  };
  const handleWillBeShippedChange = (event) => {
    setIncludeWillBeShipped(event.target.checked);
  };

  const handleMakeChange = (event) => {
    const newMake = event.target.value;
    setMake(newMake);
    setModel('');
    if (newMake && !selectedFilters.includes(newMake)) {
      setSelectedFilters([...selectedFilters, newMake]);
    } else if (!newMake) {
      setSelectedFilters(selectedFilters.filter(f => !carMakes.includes(f)));
    }
  };

  const handlePriceMinChange = (e) => {
    setPriceMin(e.target.value);
    if (e.target.value) {
      setSelectedFilters(prev => [...prev.filter(f => !f.startsWith('Min Price:')), `Min Price: $${e.target.value}`]);
    } else {
      setSelectedFilters(prev => prev.filter(f => !f.startsWith('Min Price:')));
    }
  };

  const handlePriceMaxChange = (e) => {
    setPriceMax(e.target.value);
    if (e.target.value) {
      setSelectedFilters(prev => [...prev.filter(f => !f.startsWith('Max Price:')), `Max Price: $${e.target.value}`]);
    } else {
      setSelectedFilters(prev => prev.filter(f => !f.startsWith('Max Price:')));
    }
  };

  const handleMileageMinChange = (e) => {
    setMileageMin(e.target.value);
    if (e.target.value) {
      setSelectedFilters(prev => [...prev.filter(f => !f.startsWith('Min Mileage:')), `Min Mileage: ${e.target.value} mi`]);
    } else {
      setSelectedFilters(prev => prev.filter(f => !f.startsWith('Min Mileage:')));
    }
  };

  const handleMileageMaxChange = (e) => {
    setMileageMax(e.target.value);
    if (e.target.value) {
      setSelectedFilters(prev => [...prev.filter(f => !f.startsWith('Max Mileage:')), `Max Mileage: ${e.target.value} mi`]);
    } else {
      setSelectedFilters(prev => prev.filter(f => !f.startsWith('Max Mileage:')));
    }
  };

  const filteredListings = listings.filter(car => 
    (car.make?.toLowerCase().includes(searchTerm.toLowerCase()) || car.model?.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (make === '' || car.make === make) &&
    (model === '' || car.model === model) &&
    (yearMin === '' || car.yearMade >= parseInt(yearMin)) &&
    (yearMax === '' || car.yearMade <= parseInt(yearMax)) &&
    (priceMin === '' || car.price >= parseInt(priceMin)) &&
    (priceMax === '' || car.price <= parseInt(priceMax)) &&
    (mileageMin === '' || car.odometer >= parseInt(mileageMin)) &&
    (mileageMax === '' || car.odometer <= parseInt(mileageMax)) &&
    (includeWillBeShipped || !car.willBeShipped) // This line filters out "will be shipped" cars when the checkbox is unchecked
  );

  const sortedListings = [...filteredListings].sort((a, b) => {
    if (sort === 'price') return (a.price || 0) - (b.price || 0);
    if (sort === '-price') return (b.price || 0) - (a.price || 0);
    if (sort === 'year') return (a.yearMade || 0) - (b.yearMade || 0);
    if (sort === '-year') return (b.yearMade || 0) - (a.yearMade || 0);
    if (sort === '-date') return new Date(b.datePosted) - new Date(a.datePosted);
    if (sort === 'date') return new Date(a.datePosted) - new Date(b.datePosted);
    // Default sort by datePosted (newest first)
    return new Date(b.datePosted) - new Date(a.datePosted);
  });

  const currentMakeModels = make && carData[make] ? carData[make] : [];

  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const renderFilters = () => (
    <Box className="filters">
      <Typography variant="h6" gutterBottom>Шүүлтүүр</Typography>
      {selectedFilters.map((filter) => (
        <Chip
          key={filter}
          label={filter}
          onDelete={() => handleFilterRemove(filter)}
          className="filter-chip"
        />
      ))}
        <Button onClick={() => {
          setSelectedFilters([]);
          setMake('');
          setModel('');
          setYearMin('');
          setYearMax('');
          setPriceMin('');
          setPriceMax('');
          setMileageMin('');
          setMileageMax('');
          setIncludeWillBeShipped(true); // Reset to include all cars
        }}>
          Бүгдийг Арилгах
        </Button>
      <Typography variant="h6" gutterBottom mt={2}>Үндсэн Мэдээлэл</Typography>
      <Select
        value={make}
        onChange={handleMakeChange}
        fullWidth
        margin="dense"
      >
        <MenuItem value="">Бүх Үйлдвэрлэгч</MenuItem>
        {carMakes.map((carMake) => (
          <MenuItem key={carMake} value={carMake}>{carMake}</MenuItem>
        ))}
      </Select>
      {make && (
        <Select
          value={model}
          onChange={(e) => setModel(e.target.value)}
          fullWidth
          margin="dense"
        >
          <MenuItem value="">Бүх Загвар</MenuItem>
          {currentMakeModels.map((carModel) => (
            <MenuItem key={carModel} value={carModel}>{carModel}</MenuItem>
          ))}
        </Select>
      )}
      <TextField
        label="Доод Он"
        type="number"
        value={yearMin}
        onChange={(e) => setYearMin(e.target.value)}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Дээд Он"
        type="number"
        value={yearMax}
        onChange={(e) => setYearMax(e.target.value)}
        fullWidth
        margin="dense"
      />
      <Typography variant="h6" gutterBottom mt={2}>Үнийн Хязгаар</Typography>
      <TextField
        label="Доод Үнэ"
        type="number"
        value={priceMin}
        onChange={handlePriceMinChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Дээд Үнэ"
        type="number"
        value={priceMax}
        onChange={handlePriceMaxChange}
        fullWidth
        margin="dense"
      />
      <Typography variant="h6" gutterBottom mt={2}>Гүйлтийн Хязгаар</Typography>
      <TextField
        label="Доод Гүйлт"
        type="number"
        value={mileageMin}
        onChange={handleMileageMinChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Дээд Гүйлт"
        type="number"
        value={mileageMax}
        onChange={handleMileageMaxChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Байршил"
        value={locationFilter}
        onChange={handleLocationChange}
        fullWidth
        margin="dense"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={includeWillBeShipped}
            onChange={handleWillBeShippedChange}
          />
        }
        label="Гадаадаас ачигдаж ирэх машинуудыг оруулах" // "Include cars that will be shipped" in Mongolian
      />
    </Box>
  );

  return (
    <Container maxWidth="lg" className="car-listings-page">
      <Grid container spacing={3}>
        <Grid item xs={12} className="mobile-filter-button">
          <Button 
            variant="outlined" 
            startIcon={<FilterListIcon />}
            onClick={toggleFilterDrawer}
            fullWidth
          >
            Шүүлтүүр
          </Button>
        </Grid>
        <Grid item xs={12} md={3} className="desktop-filters">
          {renderFilters()}
        </Grid>
        <Grid item xs={12} md={9}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Select
              value={sort}
              onChange={(e) => setSort(e.target.value)}
              displayEmpty
              variant="outlined"
            >
              <MenuItem value="">Хамгийн Тохирох</MenuItem>
              <MenuItem value="price">Үнэ: Багаас Ихрүү</MenuItem>
              <MenuItem value="-price">Үнэ: Ихээс Багаруу</MenuItem>
              <MenuItem value="-year">Он: Багаас Ихрүү</MenuItem>
              <MenuItem value="year">Он: Ихээс Багаруу</MenuItem>
              <MenuItem value="-date">Тавигдсан он сар: Хамгийн сүүлээс</MenuItem>
              <MenuItem value="date">Тавигдсан он сар: Хамгийн эртнээс</MenuItem>
            </Select>
          </Box>
          {sortedListings.length === 0 ? (
            <Typography>Таны шүүлтүүрт таарах хайлт олдсонгүй.</Typography>
          ) : (
            sortedListings.map((car) => (
              <Link 
                to={`/car/${car.id}`} 
                key={car.id} 
                className="car-link"
                state={{ filters: { make, model, yearMin, yearMax, priceMin, priceMax, mileageMin, mileageMax, selectedFilters, includeWillBeShipped, location: locationFilter } }}
              >
              <Card className="car-card">
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <ImageCarousel images={car.images} />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <CardContent>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Typography variant="h6" component="div" className="car-title">
                            {car.yearMade} {car.make} {car.model}
                          </Typography>
                          {car.datePosted && (
                            <Typography variant="body2" color="text.secondary">
                              ({getTimeSincePosted(car.datePosted)})
                            </Typography>
                          )}
                    </Box>
                    <IconButton onClick={(e) => toggleSave(e, car.id)}>
              {savedCars[car.id] ? (
                <FavoriteIcon className="save-icon saved" />
              ) : (
                <FavoriteBorderIcon className="save-icon" />
              )}
            </IconButton>
                  </Box>
                  <Typography variant="h5" className="car-price">
                    ₮{formatPrice(car.price)}
                  </Typography>
                      <Typography variant="body2" color="text.secondary" className="car-details">
                        {car.odometer} км | {car.engine}л | {car.gearbox} | {car.fuelType}
                      </Typography>
                      <Box className="dealer-info">
                    <Typography variant="body2">
                      <LocationOnIcon fontSize="small" style={{verticalAlign: 'middle', marginRight: '5px'}}/>
                      {car.sellerLocation}
                    </Typography>
                    <Typography variant="body2">{car.views || 0} үзсэн • {car.saves || 0} хадгалсан</Typography>
                  </Box>
                    </CardContent>
                  </Grid>
                </Grid>
              </Card>
              </Link>
            ))
          )}
          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination 
              count={totalPages} 
              page={page} 
              onChange={handlePageChange}
              color="primary"
              size="large"
            />
          </Box>
        </Grid>
      </Grid>
      <Drawer
        anchor="left"
        open={isFilterDrawerOpen}
        onClose={toggleFilterDrawer}
        className="mobile-filter-drawer"
      >
        <Box className="filters mobile-filters">
          {renderFilters()}
        </Box>
      </Drawer>
    </Container>
  );
};

export default CarListings;