// src/components/RecommendedCars.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid, Card, CardContent, CardMedia, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const CardMediaStyled = styled(CardMedia)({
  paddingTop: '56.25%', // 16:9 aspect ratio
});

const CardContentStyled = styled(CardContent)({
  flexGrow: 1,
});

const RecommendedCars = ({ currentCar, allCars }) => {
  // Filter and sort recommended cars
  const recommendedCars = allCars
    .filter(car => car.id !== currentCar.id) // Exclude current car
    .sort((a, b) => {
      // Prioritize same model
      if (a.model === currentCar.model && b.model !== currentCar.model) return -1;
      if (b.model === currentCar.model && a.model !== currentCar.model) return 1;
      
      // Then sort by views + saves
      return (b.views + b.saves) - (a.views + a.saves);
    })
    .slice(0, 6); // Get top 6 recommendations

  if (recommendedCars.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h5" gutterBottom>Танд санал болгох машинууд</Typography>
      <Grid container spacing={2}>
        {recommendedCars.map((car) => (
          <Grid item xs={12} sm={6} md={4} key={car.id}>
            <Link to={`/car/${car.id}`} style={{ textDecoration: 'none' }}>
              <StyledCard>
                <CardMediaStyled
                  image={car.images && car.images.length > 0 ? car.images[0] : 'https://via.placeholder.com/300x169'}
                  title={`${car.make} ${car.model}`}
                />
                <CardContentStyled>
                  <Typography variant="h6" component="h2" gutterBottom>
                    {car.yearMade} {car.make} {car.model} {car.trim}
                  </Typography>
                  <Typography variant="h6" color="primary" gutterBottom>
                    ₮{car.price ? car.price.toLocaleString() : 'N/A'}
                  </Typography>
                  {car.odometer && (
                    <Typography variant="body2" color="textSecondary">
                      {car.odometer.toLocaleString()} км
                    </Typography>
                  )}
                </CardContentStyled>
              </StyledCard>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RecommendedCars;